import {
  UPLOAD_TOV_LOADING,
  UPLOAD_TOV_CODE_ERROR,
  UPLOAD_TOV_SUCCESS,
  UPLOAD_TOV_FAILED,
  CLEAR_UPLOAD_TOV_STATE,
  LOAD_TOVS,
  LOAD_TOVS_ERROR,
  DELETE_TOV_LOADING,
  CLEAR_DELETE_TOV,
  DELETE_TOV_CODE_ERROR,
  DELETE_TOV_SUCCESS,
  DELETE_TOV_FAILED,
  EDIT_TOV_LOADING,
  CLEAR_EDIT_TOV,
  EDIT_TOV_CODE_ERROR,
  EDIT_TOV_SUCCESS,
  EDIT_TOV_FAILED,
} from "../../actiontypes/admin/Tov";

const initState = {
  uploadTov: "",
  loadTovs: { response: { data: [] } },
  mismatchReport: null,
  updateTov: "",
  deleteTov: "",
};

const TovReducer = (state = initState, action) => {
  switch (action.type) {
    case CLEAR_UPLOAD_TOV_STATE:
      return {
        ...state,
        uploadTov: "",
      };
    case UPLOAD_TOV_LOADING:
      return {
        ...state,
        uploadTov: "loading",
      };
    case UPLOAD_TOV_SUCCESS:
      return {
        ...state,
        uploadTov: action.res,
      };
    case UPLOAD_TOV_FAILED:
      return {
        ...state,
        uploadTov: action.res,
      };
    case UPLOAD_TOV_CODE_ERROR:
      return {
        ...state,
        loginState: "Error failed to upload guest",
      };
    case LOAD_TOVS:
      return {
        ...state,
        loadTovs: action.res,
      };
    case LOAD_TOVS_ERROR:
      return {
        ...state,
        loadTovs: action.error,
      };
    case CLEAR_DELETE_TOV:
      return {
        ...state,
        deleteTov: "",
      };
    case DELETE_TOV_LOADING:
      return {
        ...state,
        deleteTov: "loading",
      };
    case DELETE_TOV_SUCCESS:
      return {
        ...state,
        deleteTov: action.res,
      };
    case DELETE_TOV_FAILED:
      return {
        ...state,
        deleteTov: action.res,
      };
    case DELETE_TOV_CODE_ERROR:
      return {
        ...state,
        deleteTov: "Error failed to delete TOV",
      };
    case CLEAR_EDIT_TOV:
      return {
        ...state,
        updateTov: "",
      };
    case EDIT_TOV_LOADING:
      return {
        ...state,
        updateTov: "loading",
      };
    case EDIT_TOV_SUCCESS:
      return {
        ...state,
        updateTov: action.res,
      };
    case EDIT_TOV_FAILED:
      return {
        ...state,
        updateTov: action.res,
      };
    case EDIT_TOV_CODE_ERROR:
      return {
        ...state,
        updateTov: "Error failed to update TOV",
      };
    default:
      return state;
  }
};
export default TovReducer;
