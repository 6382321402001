import { toast } from "react-toastify";
import {
  uploadTovDocument,
  loadAllTovs,
  loadTovs,
  updateTov,
  deleteTov,
  tovNotifyEmail,
} from "../../../services/admin/TovService";
import {
  UPLOAD_TOV_LOADING,
  UPLOAD_TOV_CODE_ERROR,
  UPLOAD_TOV_SUCCESS,
  UPLOAD_TOV_FAILED,
  CLEAR_UPLOAD_TOV_STATE,
  LOAD_TOVS,
  LOAD_TOVS_ERROR,
  DELETE_TOV_LOADING,
  CLEAR_DELETE_TOV,
  DELETE_TOV_CODE_ERROR,
  DELETE_TOV_SUCCESS,
  DELETE_TOV_FAILED,
  EDIT_TOV_LOADING,
  CLEAR_EDIT_TOV,
  EDIT_TOV_CODE_ERROR,
  EDIT_TOV_SUCCESS,
  EDIT_TOV_FAILED,
} from "../../actiontypes/admin/Tov";

export const uploadTovAction = (credentials, props) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_UPLOAD_TOV_STATE });
    dispatch({ type: UPLOAD_TOV_LOADING });
    uploadTovDocument(credentials).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: UPLOAD_TOV_SUCCESS, res });
          props.history.push("/admin/tov-data");
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: UPLOAD_TOV_FAILED, res });
        }
      },
      (error) => {
        console.log(error);
        dispatch({ type: UPLOAD_TOV_CODE_ERROR, error });
      }
    );
  };
};

export const loadTovReports = (credentials) => {
  return (dispatch) => {
    loadTovs(credentials).then(
      (res) => {
        console.log(res);
        dispatch({ type: LOAD_TOVS, res });
      },
      (error) => {
        console.log(error);
        dispatch({ type: LOAD_TOVS_ERROR, error });
      }
    );
  };
};

export const loadAllTovReports = (credentials) => {
  return (dispatch) => {
    loadAllTovs(credentials).then(
      (res) => {
        console.log(res);
        dispatch({ type: LOAD_TOVS, res });
      },
      (error) => {
        console.log(error);
        dispatch({ type: LOAD_TOVS_ERROR, error });
      }
    );
  };
};

export const sendTovEmailAction = (credentials) => {
  return (dispatch) => {
    tovNotifyEmail(credentials).then(
      (res) => {
        console.log(res);
        if (res.hasOwnProperty("success") && res.success === true) {
          toast.success(res.response.message);
        } else if (res.hasOwnProperty("success") && res.success === false) {
          toast.error(res.response.message);
        }
      },
      (error) => {
        console.log(error);
        toast.error("Failed to send emails.");
      }
    );
  };
};

export const clearUploadTovState = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_UPLOAD_TOV_STATE });
  };
};

export const updateTovction = (credentials) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_EDIT_TOV });
    dispatch({ type: EDIT_TOV_LOADING });
    updateTov(credentials).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: EDIT_TOV_SUCCESS, res });
          toast.success(res.response.message);
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: EDIT_TOV_FAILED, res });
          toast.error(res.response.message);
        }
      },
      (error) => {
        console.log(error);
        dispatch({ type: EDIT_TOV_CODE_ERROR, error });
      }
    );
  };
};

export const deleteTovAction = (credentials) => {
  return (dispatch) => {
    dispatch({ type: CLEAR_DELETE_TOV });
    dispatch({ type: DELETE_TOV_LOADING });
    deleteTov(credentials).then(
      (res) => {
        if (res.hasOwnProperty("success") && res.success === true) {
          dispatch({ type: DELETE_TOV_SUCCESS, res });
          toast.success(res.response.message);
        } else if (res.hasOwnProperty("success") && res.success === false) {
          dispatch({ type: DELETE_TOV_FAILED, res });
          toast.error(res.response.message);
        }
      },
      (error) => {
        console.log(error);
        dispatch({ type: DELETE_TOV_CODE_ERROR, error });
      }
    );
  };
};
