import { appURL } from '../../helpers/authProvider'
class HttpService {
  //baseUrl = "http://localhost:5000";
  baseUrl = appURL.gatewayURL;//"https://m57jm6gqs4.execute-api.us-east-2.amazonaws.com/Dev";

  postData = async (item, addedUrl, postType, tokenId = "") => {
    const token = await localStorage.getItem(tokenId);
    const gatewayToken = await localStorage.getItem('gateway-access-token');
    const requestOptions = this.postRequestOptions(gatewayToken, token, item, postType);
    return fetch(this.baseUrl + "/" + addedUrl, requestOptions).then(
      (response) => response.json()
    );
  };
  postRequestOptions = (gatewayToken, token, item, postType) => {
    let requestOptions = {
      method: postType,
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + gatewayToken,
        jwt_token: token,
        "Content-type": "Application/json",
      },
      body: JSON.stringify(item),
    };
    return requestOptions;
  };

  postDataForFileDownload = async (item, addedUrl, postType, tokenId = "") => {
    const token = await localStorage.getItem(tokenId);
    const gatewayToken = await localStorage.getItem('gateway-access-token');
    const requestOptions = this.postFileDownloadRequestOptions(gatewayToken, token, item, postType);
    return fetch(this.baseUrl + "/" + addedUrl, requestOptions).then(
      (response) => response.json()
    );
  };
  postFileDownloadRequestOptions = (gatewayToken, token, item, postType) => {
    let requestOptions = {
      method: postType,
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + gatewayToken,
        jwt_token: token,
        "Content-type": "Application/json",
      },
      body: JSON.stringify(item),
    };
    return requestOptions;
  };

  getData = async (addedUrl, tokenId = "") => {
    const token = await localStorage.getItem(tokenId);
    const gatewayToken = await localStorage.getItem('gateway-access-token');
    const requestOptions = this.getRequestOptions(gatewayToken, token);
    return fetch(this.baseUrl + "/" + addedUrl, requestOptions).then(
      (response) => response.json()
    );
  };
  getRequestOptions = (gatewayToken, token) => {
    let requestOptions = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + gatewayToken,
        jwt_token: token,
        "Content-type": "Application/json",
      },
    };
    return requestOptions;
  };

  deleteData = async (addedUrl, tokenId = "") => {
    const token = await localStorage.getItem(tokenId);
    const gatewayToken = await localStorage.getItem('gateway-access-token');
    const requestOptions = this.deleteRequestOptions(gatewayToken, token);
    return fetch(this.baseUrl + "/" + addedUrl, requestOptions).then(
      (response) => response.json()
    );
  };
  deleteRequestOptions = (gatewayToken, token) => {
    let requestOptions = {
      method: "DELETE",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + gatewayToken,
        jwt_token: token,
        "Content-type": "Application/json",
      },
    };
    return requestOptions;
  };

  postDataWithFormData = async (item, addedUrl, postType, tokenId = "") => {
    const token = await localStorage.getItem(tokenId);
    const gatewayToken = await localStorage.getItem('gateway-access-token');
    const requestOptions = this.postRequestOptionsWithFormData(gatewayToken, token, item, postType);
    return fetch(this.baseUrl + "/" + addedUrl, requestOptions).then(
      (response) => response.json()
    );
  };
  postRequestOptionsWithFormData = (gatewayToken, token, item, postType) => {
    let requestOptions = {
      method: postType,
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + gatewayToken,
        jwt_token: token,
      },
      body: item,
    };
    return requestOptions;
  };
}

export default HttpService;
