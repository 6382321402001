import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import MaterialLink from '@material-ui/core/Link'

const footerStyle = props => ({
  position: 'absolute',
  left: 0,
  bottom: 0,

  borderTop: '1px solid #e7e7e7',
  backgroundColor: '#f8f8f8',
  textAlign: 'center',
  paddingTop: '10px',
  width: '100%'
})

const Footer = props => {
  var dateobj = new Date()
  let footerDetails = {
    disclaimer:
      'This website is intended for Australian Healthcare Professionals only.',
    copyRight: `Copyright ©${dateobj.getFullYear()} Eli Lilly Australia Pty Ltd. Level 9, 60 Margaret Street, Sydney NSW 2000. ABN 39 000 233 992.`,
    VVPM: 'Date of preparation: November 2024 | PP-LILLY-AU-0151',
    privacyPolicyLink: 'https://www.lillyprivacy.com/AU-en/hcp',
    termsOfUseLink: 'https://www.lilly.com.au/en/terms-of-use.aspx',
    contactUs: 'https://www.lilly.com/au/contact',
    accessibilityStatement: 'https://www.lilly.com.au/accessibility-statement'
  }
  return (
    <footer style={footerStyle(props)}>
      <>
        <Grid container className='dashboardCardContent'>
          <MaterialLink
            variant='button'
            display='block'
            gutterBottom
            className='alignCenterText'
            onClick={() =>
              window.open(`${footerDetails.termsOfUseLink}`, '_blank')
            }
          >
            Terms of Use
            <LinkIcon />
          </MaterialLink>
          <MaterialLink
            variant='button'
            display='block'
            gutterBottom
            className='alignCenterText'
            onClick={() =>
              window.open(`${footerDetails.privacyPolicyLink}`, '_blank')
            }
          >
            Privacy Policy
            <LinkIcon />
          </MaterialLink>
          <MaterialLink
            variant='button'
            display='block'
            gutterBottom
            className='alignCenterText'
            onClick={() => window.open(`${footerDetails.contactUs}`, '_blank')}
          >
            Contact Us
            <LinkIcon />
          </MaterialLink>
          <MaterialLink
            variant='button'
            display='block'
            gutterBottom
            className='alignCenterText'
            onClick={() =>
              window.open(`${footerDetails.accessibilityStatement}`, '_blank')
            }
          >
            Accessibility Statement
            <LinkIcon />
          </MaterialLink>
          <Grid container>
            <Typography
              variant='caption'
              gutterBottom
              className='alignCenterText'
            >
              {footerDetails.copyRight}
            </Typography>
          </Grid>
          <Grid container>
            <Typography
              variant='caption'
              gutterBottom
              className='alignCenterText'
            >
              {footerDetails.VVPM}
            </Typography>
          </Grid>
        </Grid>
      </>
    </footer>
  )
}
Footer.propTypes = {
  children: PropTypes.any
}

export default Footer
