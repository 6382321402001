import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AdminDashboard from "../components/admin/pages/Dashboard";
import Template from "../components/admin/partial/Template";
import Nav from "../components/admin/partial/Nav";
import TovUpload from "../components/admin/pages/TovUpload";
import TovData from "../components/admin/pages/TovData";
import ManageDispute from "../components/admin/pages/ManageDispute";
import UserManagement from "../components/admin/pages/UserManagement";
import Topbar from "../components/layouts/admin/Topbar";
import Footer from "../components/layouts/admin/Footer";

export default function AdminRoutes(props) {
  return (
    <>
      <Topbar />
      <div className="main">
        <Nav props={props} />
        <Template>
          <Switch>
            <Route
              exact
              path={`${props.match.path}/dashboard`}
              component={AdminDashboard}
            />
            <Route
              exact
              path={props.match.path}
              render={(props) => (
                <Redirect to={{ pathname: `${props.match.path}/dashboard` }} />
              )}
            />
            <Route
              exact
              path={`${props.match.path}/tov-upload`}
              component={TovUpload}
            />
            
            <Route
              exact
              path={`${props.match.path}/tov-data`}
              component={TovData}
            />
            <Route
              exact
              path={`${props.match.path}/manage-dispute`}
              component={ManageDispute}
            />
            <Route
              exact
              path={`${props.match.path}/user-management`}
              component={UserManagement}
            />
          </Switch>
        </Template>
        <Footer />
      </div>
    </>
  );
}
