export const UPLOAD_TOV_LOADING = 'UPLOAD_TOV_LOADING';

export const UPLOAD_TOV_CODE_ERROR = 'UPLOAD_TOV_CODE_ERROR';

export const UPLOAD_TOV_SUCCESS = 'UPLOAD_TOV_SUCCESS';

export const UPLOAD_TOV_FAILED = 'UPLOAD_TOV_FAILED';

export const CLEAR_UPLOAD_TOV_STATE = 'CLEAR_UPLOAD_TOV_STATE';


export const LOAD_TOVS = 'LOAD_TOVS';

export const LOAD_TOVS_ERROR = 'LOAD_TOVS_ERROR';


export const DELETE_TOV_LOADING = 'DELETE_TOV_LOADING';

export const CLEAR_DELETE_TOV = 'CLEAR_DELETE_TOV';

export const DELETE_TOV_CODE_ERROR = 'DELETE_TOV_CODE_ERROR';

export const DELETE_TOV_SUCCESS = 'DELETE_TOV_SUCCESS';

export const DELETE_TOV_FAILED = 'DELETE_TOV_FAILED';


export const EDIT_TOV_LOADING = 'EDIT_TOV_LOADING';

export const CLEAR_EDIT_TOV = 'CLEAR_EDIT_TOV';

export const EDIT_TOV_CODE_ERROR = 'EDIT_TOV_CODE_ERROR';

export const EDIT_TOV_SUCCESS = 'EDIT_TOV_SUCCESS';

export const EDIT_TOV_FAILED = 'EDIT_TOV_FAILED';