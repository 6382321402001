import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../../common";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { useFormFields } from "../../../helpers/hooksFormInput";
import {
  uploadTovAction,
  clearUploadTovState,
} from "../../../stores/actions/admin/TovActions";
import { downloadTemplate } from "../../../services/admin/DisputeService";

const TovUpload = (props) => {
  const d = new Date();
  const crntMonth = d.getMonth();
  const crntYear = d.getFullYear();
  const preYear = crntYear - 1;
  const nxtYear = crntYear + 1;
  var option1, option2, option1Value, option2Value;
  if (crntMonth >= 4 && crntMonth <= 9) {
    option1 = `01-May-${crntYear} to 31-Oct-${crntYear}`;
    option2 = `01-Nov-${preYear} to 30-Apr-${crntYear}`;
    option1Value = crntYear + "-05-01 to " + crntYear + "-10-31";
    option2Value = preYear + "-11-01 to " + crntYear + "-04-30";
  } else if (crntMonth < 4) {
    option1 = `01-Nov-${preYear} to 30-Apr-${crntYear}`;
    option2 = `01-May-${preYear} to 31-Oct-${preYear}`;
    option1Value = preYear + "-11-01 to " + crntYear + "-04-30";
    option2Value = preYear + "-05-01 to " + preYear + "-10-31";
  } else {
    option1 = `01-Nov-${crntYear} to 30-Apr-${nxtYear}`;
    option2 = `01-May-${crntYear} to 31-Oct-${crntYear}`;
    option1Value = crntYear + "-11-01 to " + nxtYear + "-04-30";
    option2Value = crntYear + "-05-01 to " + crntYear + "-10-31";
  }

  const token = localStorage.getItem("admin-token");
  const auth = jwt_decode(token);

  const [fields, setFields, handleFieldsChange] = useFormFields({
    tov_daterange: option1Value,
    uploadfile: "",
    filename: "",
  });

  const uploadTovResponse = useSelector((state) => state.adminTov.uploadTov);

  let columns = [
    {
      dataField: "row",
      text: "Row",
    },
    {
      dataField: "column",
      text: "Column",
    },
    {
      dataField: "invalid_value",
      text: "Invalid Value",
    },
  ];

  const [disable, setDisable] = useState(true);
  const [errorRecords, setErrorRecords] = useState([]);
  const [incorrectTovRecords, setIncorrectTovRecords] = useState(false);
  const dispatch = useDispatch();
  const onFileChange = (e) => {
    let reader = new FileReader();
    reader.onload = function () {
      setFields({
        ...fields,
        uploadfile: this.result,
        filename: e.target.files[0].name,
      });
    };
    reader.readAsBinaryString(e.target.files[0]);
    checkIfFormFieldsIsFilledProperly(fields);
  };

  useEffect(() => {
    if (
      uploadTovResponse.success === true &&
      uploadTovResponse.response.message
    ) {
      toast.success(uploadTovResponse.response.message);
      dispatch(clearUploadTovState());
    } else if (
      uploadTovResponse.success === true &&
      uploadTovResponse.response.data
    ) {
      //handle by displaying a table
      setErrorRecords(uploadTovResponse.response.data);
      dispatch(clearUploadTovState());
      toast.error("Invalid record/s found.");
      setIncorrectTovRecords(true);
    } else if (uploadTovResponse.success === false) {
      toast.error(uploadTovResponse.response.message);
      dispatch(clearUploadTovState());
    }
    return () => {};
  }, [uploadTovResponse, dispatch]);

  useEffect(() => {
    checkIfFormFieldsIsFilledProperly(fields);
  }, [fields]);

  const checkIfFormFieldsIsFilledProperly = (formFields) => {
    for (let objects in formFields) {
      if (formFields[objects] === "" || formFields[objects] === null) {
        return setDisable(true);
      }
    }
    return setDisable(false);
  };

  const validation = () => {
    const errors = {};
    errors.uploadfile =
      fields.uploadfile === "" ? "file upload is required" : "";
    errors.tov_daterange =
      fields.tov_daterange === "" ? "tov date range is required" : "";
    return errors;
  };

  const uploadNewTov = (e) => {
    e.preventDefault();
    let model = {
      user_id: auth.user.id,
      tov_daterange: fields.tov_daterange,
      uploadfile: fields.uploadfile,
      filename: fields.filename,
    };
    dispatch(uploadTovAction(model, props));
    e.target.reset();
    setDisable(true);
  };

  const closeIncorrectTovModal = () => {
    setIncorrectTovRecords(false);
  };
  const downloadTemplateFile = (e) => {
    e.preventDefault();
    downloadTemplate();
  };

  return (
    <>
      <div className="intro_text">
        <span>Upload TOV Data &nbsp;</span>
        <img
          src="/img/arrow_back.png"
          alt="Back arrow"
          width="12"
          height="16"
        />
      </div>
      <div className="font-custom form-margin-admin">
        <Formik initialValues={fields} validate={validation}>
          {(errors, touched, handleBlur) => (
            <form
              action=""
              method="post"
              name="uploadTovForm"
              onSubmit={uploadNewTov}
            >
              <div className="col-md-6">
                <div className="form-group">
                  <select
                    className="form-control"
                    name="tov_daterange"
                    id="tov_daterange"
                    onChange={handleFieldsChange}
                    onBlur={handleBlur}
                  >
                    <option value={option1Value}>{option1}</option>
                    <option value={option2Value}>{option2}</option>
                  </select>
                </div>
                <div className="error_form_response">
                  {errors.tov_daterange &&
                    touched.tov_daterange &&
                    errors.tov_daterange}
                </div>
                <div className="form-group">
                  <input
                    type="file"
                    className="form-control"
                    id="uploadfile"
                    name="uploadfile"
                    accept=".xls,.xlsx"
                    onChange={onFileChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="error_form_response">
                  {errors.uploadfile && touched.uploadfile && errors.uploadfile}
                </div>
                <div className="row-md-12">
                  <button
                    type="submit"
                    className="btn btn-primary btn-custom"
                    disabled={disable}
                  >
                    Submit
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary btn-custom"
                    onClick={(e) => {
                      downloadTemplateFile(e);
                    }}
                  >
                    {" "}
                    Download Template
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
      <Modal
        id="incorrectRecordsTable"
        show={incorrectTovRecords}
        onHide={closeIncorrectTovModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div
              style={{ color: "red", fontSize: "15px", textAlign: "center" }}
            >
              Following invalid records found in excel.
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <div className="font-custom form-margin-admin">
            <Table
              keyField="id"
              data={errorRecords}
              columns={columns}
              wrapperClasses="table-responsive"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TovUpload;
